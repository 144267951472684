<script setup>
import { ref, computed, defineEmits, onMounted, nextTick } from 'vue'
import { useStore } from 'vuex'
import { validateForms, mobile, schemaMaker } from '@valid/JoiValidation'

const emit = defineEmits(['submit-inquiry'])
const store = useStore()

const btnLoading = ref(false)
const customerMobile = computed({
  get() {
    return store.state.footer.customerMobile
  },
  set(value) {
    store.commit('UPDATE_FORM_ITEMS', {
      field: 'customerMobile',
      value
    })
  }
})

const searchCustomerOffer = () => {
  // get items
  const formItems = { mobile: customerMobile.value }

  const validMobile = schemaMaker({
    mobile: mobile
  })

  const errors = validateForms(formItems, validMobile)

  if (errors && errors.length > 0) {
    for (let key of errors) {
      const message = { type: 'error', message: key }
      store.dispatch('addToast', message)
    }
    return
  }

  emit('submit-inquiry')
}

const input = ref(null)
onMounted(() => {
  nextTick(() => {
    input.value.$el.querySelector('input').focus()
    setTimeout(() => window.scrollTo({ top: 400 }), 100)
  })
})
</script>

<template>
  <form
    novalidate
    class="modal-form-wrapper"
    @submit.prevent="searchCustomerOffer"
  >
    <div class="footer-modal">
      <h4 class="footer-modal__text">
        دریافت تخفیف
      </h4>
    </div>
    <base-input
      input-type="number"
      label="شماره همراه"
      start-left
      phone-number
      v-model="customerMobile"
      ref="input"
    />
    <div class="credit-buttons">
      <button type="submit" class="button button--black">
        <svg
          class="button__icon"
          viewBox="0 0 700 512"
          style="width: 34px; height:34px"
          v-show="!btnLoading"
        >
          <path
            fill="#c69d4e"
            d="M189.67 484.6l148.71 0c7.49,0 13.61,6.11 13.61,13.61 0,7.49 -6.13,13.59 -13.61,13.59l-178.23 0c-15.57,0 -15.51,-13.52 -15.51,-26.79l0 -166.94c0,-7.49 6.11,-13.62 13.61,-13.62 7.49,0 13.6,6.12 13.6,13.62l0 144.87 355.52 -457.66c4.62,-5.95 13.22,-7.04 19.18,-2.41 5.97,4.63 7.07,13.23 2.43,19.2l-359.31 462.53zm21.28 -226.84c-71.19,0 -128.87,-57.7 -128.87,-128.88 0,-71.16 57.7,-128.88 128.87,-128.88 71.18,0 128.9,57.69 128.9,128.88 0,71.21 -57.71,128.88 -128.9,128.88zm278.06 254.24c-71.17,0 -128.86,-57.71 -128.86,-128.88 0,-71.16 57.7,-128.88 128.86,-128.88 71.19,0 128.91,57.69 128.91,128.88 0,71.21 -57.71,128.88 -128.91,128.88zm-278.06 -484.64c-55.98,0 -101.51,45.56 -101.51,101.52 0,55.97 45.53,101.52 101.51,101.52 55.97,0 101.53,-45.54 101.53,-101.52 0,-55.98 -45.56,-101.52 -101.53,-101.52zm278.06 254.24c-55.97,0 -101.49,45.56 -101.49,101.52 0,55.97 45.52,101.52 101.49,101.52 55.98,0 101.54,-45.53 101.54,-101.52 0,-55.98 -45.56,-101.52 -101.54,-101.52z"
          />
        </svg>
        <spinner v-if="btnLoading" />
      </button>
    </div>
  </form>
</template>
